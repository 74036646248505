<h4>Complete the Multi-Factor Authentication Process</h4>
<div class="small">
    <p>Welcome!</p>
    <!-- <p>GEP implements a Multi-Factor Authentication Process to ensure the safety of our users. You will be required to insert the one-time code every time you need to access the platform. Please use a device that is easily accessible to you. </p>
    
    <p>Please follow the step-by-step process to access the authenticator app, to know more <a>Click Here</a> </p> -->
    <!-- <ol class="list">
        <li>
            <p>
                You will need an authenticator mobile app to complete this process, such as one of the following:
                <ul>
                    <li>Google Authenticator</li>
                    <li>Microsoft Authenticator</li> 
                </ul>
            </p>
        </li>
        <li>
            <p>Use the app to scan the QR code.</p>
        </li>
        <li>
            <p>Enter the code generated by the app.</p>
        </li>
        <li>
            <p>
                After scanning the QR code above, enter the six-digit code generated by your authenticator
            </p>
        </li>
    </ol> -->
    <qrcode [qrdata]="authResult.authenticator.authenticatorUri" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
    <app-multi-factor></app-multi-factor>
</div>