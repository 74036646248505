/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./authenticator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/angularx-qrcode/angularx-qrcode.ngfactory";
import * as i3 from "angularx-qrcode";
import * as i4 from "../multi-factor/multi-factor.component.ngfactory";
import * as i5 from "../multi-factor/multi-factor.component";
import * as i6 from "../app.service";
import * as i7 from "@angular/router";
import * as i8 from "ngx-bootstrap/modal";
import * as i9 from "../multi-factor/multi-factor.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@ngx-translate/core";
import * as i12 from "ngx-cookie-service";
import * as i13 from "./authenticator.component";
var styles_AuthenticatorComponent = [i0.styles];
var RenderType_AuthenticatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthenticatorComponent, data: {} });
export { RenderType_AuthenticatorComponent as RenderType_AuthenticatorComponent };
export function View_AuthenticatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Complete the Multi-Factor Authentication Process"])), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome!"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "qrcode", [], null, null, null, i2.View_QRCodeComponent_0, i2.RenderType_QRCodeComponent)), i1.ɵdid(6, 4767744, null, 0, i3.QRCodeComponent, [i1.Renderer2, i1.PLATFORM_ID], { qrdata: [0, "qrdata"], errorCorrectionLevel: [1, "errorCorrectionLevel"], width: [2, "width"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-multi-factor", [], null, null, null, i4.View_MultiFactorComponent_0, i4.RenderType_MultiFactorComponent)), i1.ɵdid(8, 114688, null, 0, i5.MultiFactorComponent, [i6.AppService, i7.Router, i7.ActivatedRoute, i8.BsModalService, i9.MultiFactorService, i10.Title, i11.TranslateService, i12.CookieService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.authResult.authenticator.authenticatorUri; var currVal_1 = "M"; var currVal_2 = 128; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 8, 0); }, null); }
export function View_AuthenticatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-authenticator", [], null, null, null, View_AuthenticatorComponent_0, RenderType_AuthenticatorComponent)), i1.ɵdid(1, 114688, null, 0, i13.AuthenticatorComponent, [i6.AppService, i7.Router, i7.ActivatedRoute, i10.Title, i11.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticatorComponentNgFactory = i1.ɵccf("app-authenticator", i13.AuthenticatorComponent, View_AuthenticatorComponent_Host_0, {}, {}, []);
export { AuthenticatorComponentNgFactory as AuthenticatorComponentNgFactory };
