/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./flags.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/ngx-select-dropdown/ngx-select-dropdown.ngfactory";
import * as i3 from "ngx-select-dropdown";
import * as i4 from "@angular/forms";
import * as i5 from "./flags.component";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/router";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../app.service";
import * as i10 from "@angular/common/http";
var styles_FlagsComponent = [i0.styles];
var RenderType_FlagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FlagsComponent, data: {} });
export { RenderType_FlagsComponent as RenderType_FlagsComponent };
export function View_FlagsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "mx-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "ngx-select-dropdown", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"], [null, "click"], [null, "blur"], [null, "focus"], ["document", "click"], ["document", "keydown"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).clickInsideComponent() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).blur() !== false);
        ad = (pd_1 && ad);
    } if (("focus" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).focus() !== false);
        ad = (pd_2 && ad);
    } if (("document:click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).clickOutsideComponent() !== false);
        ad = (pd_3 && ad);
    } if (("document:keydown" === en)) {
        var pd_4 = (i1.ɵnov(_v, 2).KeyPressOutsideComponent() !== false);
        ad = (pd_4 && ad);
    } if (("keydown" === en)) {
        var pd_5 = (i1.ɵnov(_v, 2).handleKeyboardEvent($event) !== false);
        ad = (pd_5 && ad);
    } if (("change" === en)) {
        var pd_6 = (_co.changeSelectedCountryCode($event) !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.selectedLanguage = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, i2.View_NgxSelectDropdownComponent_0, i2.RenderType_NgxSelectDropdownComponent)), i1.ɵdid(2, 4833280, null, 0, i3.NgxSelectDropdownComponent, [i1.ChangeDetectorRef, i1.ElementRef], { options: [0, "options"], config: [1, "config"], multiple: [2, "multiple"] }, { change: "change" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.NgxSelectDropdownComponent]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.locales; var currVal_8 = _co.config; var currVal_9 = false; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.selectedLanguage; _ck(_v, 4, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_FlagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-flags", [], null, null, null, View_FlagsComponent_0, RenderType_FlagsComponent)), i1.ɵdid(1, 114688, null, 0, i5.FlagsComponent, [i6.TranslateService, i7.Router, i8.Title, i9.AppService, i10.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FlagsComponentNgFactory = i1.ɵccf("app-flags", i5.FlagsComponent, View_FlagsComponent_Host_0, {}, {}, []);
export { FlagsComponentNgFactory as FlagsComponentNgFactory };
